export default {
  getDevicesArray (state) {
    return state.devicesArray;
  },
  getLoadingState (state) {
    return state.loadingState;
  },
  getDevicesCounter (state) {
    return state.devicesCounter;
  }
};