export default {
  async loadPersonal  (store) {
    store.commit('setLoadingState', true);
    const tableInfo = store.rootGetters['data/getTableInfo'];
    const tableFilter = store.rootGetters['data/getTableFilter'];
    const settingsPage = {currentPage: tableInfo.page, itemsPerPage: tableInfo.itemsPerPage};
    const settingsFilter = {filterText: tableFilter.filterText, filterSettings: tableFilter.filterSettings};
    const settings = Object.assign(settingsPage, settingsFilter);
    
    let data = null;
    try {
      data = await this.$axios.$post('/personal', settings);
    } catch (error) {
      if (error.response.status === 401) {
        this.$auth.logout();
        this.$router.push('/login');
        throw (error);
      }
    }

    const tableData = data.data;
    const counter = data.itemsCounter;
    for (let i = 0; i < tableData.length; i++) {
      tableData[i].anzGeraete = tableData[i].geraete.length;
    }
    store.commit('setPersonalArray', tableData);
    store.commit('setLoadingState', false);
    store.commit('setCounter', counter);
  },
  comparePersonal ({ commit, state }, {data, operationType}) {
    const dataToCheck = data.data;
    dataToCheck.anzGeraete = dataToCheck.geraete.length;
    if (operationType === "insert") {
      commit('updatePersonalArray', {dataToCheck, operationType});
    } else {
      for (let i = 0; i < state.personalArray.length; i++) {
        if (state.personalArray[i]._id === dataToCheck._id) {
          commit('updatePersonalArray', {dataToCheck, i});
        }
      }
    }
  }
};
