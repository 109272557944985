
export default {
  name: 'EmptyLayout',
  layout: 'empty',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      pageNotFound: 'Diese Seite wurde nicht gefunden',
      otherError: 'Ein Fehler ist aufgetreten'
    };
  },
  head () {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError;
    return {
      title
    };
  },
  methods: {
    getError() {
      if (this.error.statusCode !== 404) {
        return window.btoa(JSON.stringify(this.error) + " error message:" + this.error?.response?.data?.message);
      }
    }
  }
};
