export default {
  async getDeliveryArray (state) {
    const deliverArray = await this.$axios.$get('/getDeliveryArray');
    state.commit('setDeliveryArray', deliverArray);
  },
  async getData (state) {
    const data = await this.$axios.$get('/getLocations');
    const fullLocationData = await this.$axios.$get('/getFullLocationData');
    const deviceData = await this.$axios.$get('/getDeviceData'); // deviceData
    const locationsWithAll = [...data.allLocations];
    locationsWithAll.push({text: "Alle", value: null});
    for (let i = 0; i < locationsWithAll.length - 1; i++) {
      locationsWithAll[i].positions.push({text: "Alle", value: null});
    }
    let objKeys = [];
    function sortFunc(a, b) {
      return Number(a.text.split(".")[1]) - Number(b.text.split(".")[1]) || Number(a.text.split(".")[0].split("-")[1]) - Number(b.text.split(".")[0].split("-")[1]);
    }
    for (let i = 0; i < fullLocationData.length; i++) {
      for (let u = 0; u < fullLocationData[i].positions.length; u++) {
        if (fullLocationData[i].positions[u].departments) {
          objKeys = Object.keys(fullLocationData[i].positions[u].departments);
          for (let o = 0; o < objKeys.length; o++) {
            fullLocationData[i].positions[u].departments[objKeys[o]].workspaces.sort(sortFunc);
          }
        }
      }
    }
    state.commit("setDeviceData", deviceData); // deviceData
    state.commit("setFullLocationData", fullLocationData);
    state.commit("setLocationsWithAll", locationsWithAll);
    state.commit("setLocationText", data.locationsText);
    state.commit("setUserLocation", data.userLocation);
    state.commit("setLocationData", data.allLocations);
  },
  async changeLocation (state, data) {
    state.commit('setOverlayStatus', true);
    const app = this.app;
    const newLocationData = data.newLocation;
    if (data.update === "company") {
      state.commit("setLocationCompany", newLocationData);
      state.commit("setLocationPosition", null);
    } else if (data.update === "position") {
      state.commit("setLocationPosition", newLocationData);
    }
    const response = await this.$axios.$post('/changeLocation', data);
    if (response === "success") {
      state.commit('setCurrentPage', 1);
      state.commit('setItemsPerPage', 10);
      state.commit('setTableFilterText', "");
      state.commit('setTableFilterSettings', {});

      switch (app.router.currentRoute.name) {
        case "devices-devices":
          await state.dispatch("devices/loadDevices", {}, { root: true });
          break;
        case "personal-personal":
          await state.dispatch("personal/loadPersonal", {}, { root: true });
          break;
        case "workspace":
          await state.dispatch("workspace/loadWorkspace", {}, { root: true });
      }
      state.commit('setOverlayStatus', false);
    }
  },
  // WATCH
  watchDataChange (state) {
    const changedDataStream = new EventSource(`${this.$axios.defaults.baseURL}/watch`);
    state.commit("data/setDataStream", changedDataStream, { root: true });
    state.state.changedDataStream.onmessage = (event) => {
      if (event.data !== "👌🏼") {
        state.commit('setLoadingBarStatus', true);
        state.dispatch("watchFunction", event);
      }
    };
  },
  async watchFunction (state, event) {
    const changedDataObj = JSON.parse(event.data);
    const collection = changedDataObj.coll;
    const app = this.app;
    const currentRoute = app.router.currentRoute.name;
    if (collection === "workspace" || collection === "locations" || collection === "personal") {
      state.dispatch("getData");
    }
    if (collection === "inventur") {
      if (changedDataObj.isConfirmed) {
        state.commit('inventur/deleteIdFromDeviceList', changedDataObj.id, { root: true });
      }
    }
    if (currentRoute === "devices-devices" && (collection === "devices" || collection === "locations")) {
      state.dispatch("devices/loadDevices", {}, { root: true });
    } else if (currentRoute === "personal-personal" && (collection === "personal" || collection === "locations")) {
      state.dispatch("personal/loadPersonal", {}, { root: true });
    } else if (currentRoute === "personal-personal" && collection === "personal") {
      state.dispatch("personal/loadPersonal", {}, { root: true });
    } else if (currentRoute === "protocol-protocol" && collection === "protocol") {
      await state.dispatch('protocol/loadProtocol', {}, { root: true });
    } else if (currentRoute === "workspace" && (collection === "workspace" || collection === "locations")) {
      state.commit('data/setReloadStatus', true, { root: true });
    } else if (currentRoute === "management-locations" && collection === "locations") {
      state.commit('data/setReloadStatus', true, { root: true });
      await state.dispatch('management/loadUsersData', {}, { root: true });
    } else if (currentRoute === "management-users" && collection === "user") {
      await state.dispatch('management/loadUsersData', {}, { root: true });
    }
    state.commit('setLoadingBarStatus', false);
  },
};