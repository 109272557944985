import dayjs from 'dayjs';
export default (context, inject) => {
  inject('showOverlay', () => {
    context.store.commit('data/setOverlayStatus', true);
  });
  inject('hideOverlay', () => {
    context.store.commit('data/setOverlayStatus', false);
  });
  inject('formatDate', (date) => {
    return dayjs(date).format('DD.MM.YYYY');
  });
  inject('formatDateObject', (date) => {
    if (date) {
      return dayjs(date).format('YYYY-MM-DD');
    }
  });
};