export default () => ({ 
  userLocation: {},
  locationData: [],
  locationText: [],
  locationsWithAll: [],
  deviceData: {},
  fullLocationData: [],
  changedDataStream: undefined,
  tableInfo: {maxPage: 1, page: 1, modulo10: false, itemsPerPage: 10},
  tableFilter: {filterText: "", filterSettings: {}},
  snackbarAlert: {active: false, type: "success", text: ""},
  overlayStatus: false,
  loadingBarStatus: false,
  deliveryArray: undefined,
  reloadStatus: false,
});