export default {
  async loadProtocol  (store) {
    store.commit('setLoadingState', true);
    const tableInfo = store.rootGetters['data/getTableInfo'];
    const tableFilter = store.rootGetters['data/getTableFilter'];
    const settingsPage = {currentPage: tableInfo.page, itemsPerPage: tableInfo.itemsPerPage};
    const settingsFilter = {filterText: tableFilter.filterText, filterSettings: tableFilter.filterSettings};
    const settings = Object.assign(settingsPage, settingsFilter);
    const data = await this.$axios.$post('/protocol', settings).catch((error) => {
      if (error.response.status === 401) {
        this.$auth.logout();
        this.$router.push('/login');
        throw (error);
      }
    });

    const tableData = data.data;
    const counter = data.itemsCounter;

    tableData.forEach((element) => { //  Datum formatieren
      if (element.uebergabedatum !== undefined) {
        element.uebergabedatum = this.$formatDate(element.uebergabedatum);
      }
      if (element.hochgeladen.includes("-")) {
        element.hochgeladen = this.$formatDate(element.hochgeladen);
      }
    });
    
    for (let i = 0; i < tableData.length; i++) { //  Namen formatieren
      tableData[i].empfaenger.fullName = tableData[i].empfaenger.name.vorname + " " + tableData[i].empfaenger.name.nachname;
      tableData[i].uebergeber.fullName = tableData[i].uebergeber.name.vorname + " " + tableData[i].uebergeber.name.nachname;
    }

    store.commit('setProtocolArray', tableData);
    store.commit('setLoadingState', false);
    store.commit('setCounter', counter);
  },
  compareProtocol ({ commit, state }, {data, operationType}) {
    const dataToCheck = data.data;

    if (dataToCheck.uebergabedatum !== undefined) {
      dataToCheck.uebergabedatum = this.$formatDate(dataToCheck.uebergabedatum);
    }
    if (dataToCheck.hochgeladen.includes("-")) {
      dataToCheck.hochgeladen = this.$formatDate(dataToCheck.hochgeladen);
    }

    //  Namen formatieren
    dataToCheck.empfaenger.fullName = dataToCheck.empfaenger.name.vorname + " " + dataToCheck.empfaenger.name.nachname;
    dataToCheck.uebergeber.fullName = dataToCheck.uebergeber.name.vorname + " " + dataToCheck.uebergeber.name.nachname;

    if (operationType === "insert") {
      commit('updateProtocolArray', {dataToCheck, operationType});
    } else {
      for (let i = 0; i < state.protocolArray.length; i++) {
        if (state.protocolArray[i]._id === dataToCheck._id) {
          commit('updateProtocolArray', {dataToCheck, i});
        }
      }
    }
  }
};
