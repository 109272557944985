
export default {
  name: 'DefaultLayout',
  
  data () {
    return {
      requiredRule: [v => !!v || 'Feld wird benötigt'],
      showPasswordChange: false,
      passwordModel: {
        oldPassword: "",
        newPassword: "",
        confNewPassword: ""
      },
      menu: false,
      company: null,
      companyPosition: null,
      navigationItems: [
        {
          icon: 'mdi-laptop',
          title: 'Geräte',
          to: '/devices'
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Personal',
          to: '/personal'
        },
        {
          icon: 'mdi-newspaper-variant-multiple',
          title: 'Protokolle',
          to: '/protocol'
        },
        {
          icon: 'mdi-desk',
          title: 'Arbeitsplätze',
          to: '/workspace'
        },
        {
          icon: 'mdi-note-multiple',
          title: 'Pläne',
          subLinks: [
            {
              icon: 'mdi-floor-plan',
              title: 'Technikplan',
              to: '/technicPlan'
            },
            {
              icon: 'mdi-cctv',
              title: 'Kameraplan',
              to: '/cameraPlan'
            }
          ],
        },
        {
          icon: 'mdi-nas',
          title: 'Inventur',
          to: '/inventur'
        },
        {
          icon: 'mdi-tune',
          title: 'Management',
          subLinks: [
            {
              icon: 'mdi-map-marker-multiple',
              title: 'Standorte',
              to: '/management/locations'
            },
            {
              icon: 'mdi-account-cog',
              title: 'Nutzerverwaltung',
              to: '/management/users'
            },
          ],
         
        },
      ],
      miniVariant: false,
      title: 'Inventarsystem',
      pbURLValid: false,
      newPasswordModel: {
        newPassword: "", 
        newPasswordConfirm: ""
      },
      showPassword: false,
      showPasswordConfirm: false,
      showChangePasswordText: [
        false, false, false
      ],
      minPassword: (v) => {
        if (v.length < 8) {
          return "Das Passwort muss mindestens 8 Zeichen lang sein.";
        } else {
          return true;
        }
      },
      matchPasswordRule: (v) => {
        if (v.length < 8) {
          return "Mindestens 8 Zeichen";
        } else if (v !== this.newPasswordModel.newPassword) {
          return "Die Passwörter stimmen nicht überein";
        } else {
          return true;
        }
      },
    };
  },

  async fetch() {
    if (this.$auth.loggedIn) {
      await this.$store.dispatch('data/getData');
    }
  },

  computed: {
    getLocationData () {
      return this.$store.getters['data/getLocationsWithAll'];
    },
    getSnackbarAlert() {
      return this.$store.getters['data/getSnackbarAlert'];
    },
    getSnackbarAlertStatus: {
      get: function () {
        return this.$store.getters['data/getSnackbarAlert'].active;
      },
      set: function () {
        this.$store.commit('data/closeSnackbarAlert');
      }
    },
    getLocationCompany: {
      get: function () {
        return (this.$store.getters['data/getUserLocation'].company);
      },
      set: function (newLocation) {
        this.$store.dispatch('data/changeLocation', {newLocation, update: "company"});
      },
    },
    getLocationPosition: {
      get: function () {
        return (this.$store.getters['data/getUserLocation'].position);
      },
      set: function (newLocation) {
        this.$store.dispatch('data/changeLocation', {newLocation, update: "position"});
      }
    }
  },
  
  watch: {
    "$auth.loggedIn" (val) {
      this.checkPermissions();
    },
  },
  mounted () {
    this.checkPermissions();
    // eslint-disable-next-line no-useless-escape, no-console
    console.log(' ______                                            __                         \r\n\/      |                                          \/  |                        \r\n$$$$$$\/  _______   __     __  ______   _______   _$$ |_     ______    ______  \r\n  $$ |  \/       \\ \/  \\   \/  |\/      \\ \/       \\ \/ $$   |   \/      \\  \/      \\ \r\n  $$ |  $$$$$$$  |$$  \\ \/$$\/\/$$$$$$  |$$$$$$$  |$$$$$$\/    $$$$$$  |\/$$$$$$  |\r\n  $$ |  $$ |  $$ | $$  \/$$\/ $$    $$ |$$ |  $$ |  $$ | __  \/    $$ |$$ |  $$\/ \r\n _$$ |_ $$ |  $$ |  $$ $$\/  $$$$$$$$\/ $$ |  $$ |  $$ |\/  |\/$$$$$$$ |$$ |      \r\n\/ $$   |$$ |  $$ |   $$$\/   $$       |$$ |  $$ |  $$  $$\/ $$    $$ |$$ |      \r\n$$$$$$\/ $$\/   $$\/     $\/     $$$$$$$\/ $$\/   $$\/    $$$$\/   $$$$$$$\/ $$\/       \r\n                                                                              \r\n                                                                              \r\n                                                                              ');
  },

  methods: {
    async logout() {
      this.menu = false;
      this.$router.push('/devices');
      await this.$auth.logout();
    },
    async changePassword(type) {
      let response = null;
      if (type === 'newPW') {
        if (this.$refs.newPasswordForm.validate()) {
          if (this.newPasswordModel.newPassword === this.newPasswordModel.newPasswordConfirm) {
            const dataModel = {};
            dataModel.newPassword = this.newPasswordModel.newPassword;
            dataModel.firstLogin = true;

            try {
              response = await this.$axios.$post('/auth/changePassword', dataModel);
            } catch (error) {
              if (error.response.data.message === 'pw already changed') {
                const userData = {...this.$auth.user};
                userData.firstLogin = false;
                this.$auth.setUser(userData);
                throw error;
              }
            }
            if (response === "success") {
              this.newPasswordModel.newPassword = "";
              this.newPasswordModel.newPasswordConfirm = "";

              const userData = {...this.$auth.user};
              userData.firstLogin = false;
              this.$auth.setUser(userData);

              this.$store.commit('data/setSnackbarAlert', {text: "Password wurde erfolgreich geändert", type: 'success'});
            }
          } else {
            this.$store.commit('data/setSnackbarAlert', {text: "Die Passwörter stimmen nicht überein", type: 'error'});
          }
        }
      } else if (type === 'changePW') {
        if (this.$refs.changePasswordForm.validate()) {
          if (this.passwordModel.newPassword === this.passwordModel.confNewPassword) {
            this.passwordModel.firstLogin = false;
            try {
              response = await this.$axios.$post('/auth/changePassword', this.passwordModel);
            } catch (error) {
              if (error.response.data.message === 'password invalid') {
                this.$store.commit('data/setSnackbarAlert', {text: "Das alte Passwort ist falsch", type: 'error'});
              }
            }
            if (response === "success") {
              this.passwordModel = {};
              this.$store.commit('data/setSnackbarAlert', {text: "Password wurde erfolgreich geändert", type: 'success'});
              this.showPasswordChange = false;
            }
          } else {
            this.$store.commit('data/setSnackbarAlert', {text: "Die Passwörter stimmen nicht überein", type: 'error'});
          }
        }
      }
    },
    checkPermissions() {
      if (this.$auth.loggedIn !== false) {
        const arrayIndex = this.navigationItems.findIndex(o => o.title === "Pläne");
        if (this.$auth.user.permission.showCameraPlan !== true) {
          this.navigationItems[arrayIndex].subLinks.splice(this.navigationItems[arrayIndex].subLinks.findIndex(o => o.to === "/cameraPlan"), 1);
        }
        if (this.$auth.user.permission.showTechnicPlan !== true) {
          this.navigationItems[arrayIndex].subLinks.splice(this.navigationItems[arrayIndex].subLinks.findIndex(o => o.to === "/technicPlan"), 1);
        }
        if (this.$auth.user.permission.management !== true) {
          this.navigationItems.splice([this.navigationItems.findIndex(o => o.title === "Management")], 1);
        }
        if (this.$auth.user.permission.inventur !== true) {
          this.navigationItems.splice([this.navigationItems.findIndex(o => o.title === "Inventur")], 1);
        }
        this.$store.dispatch('data/watchDataChange');
        this.$store.dispatch('data/getDeliveryArray');
        this.checkURL(this.$auth.user.profilePicture);
      }
    },
    checkURL(data) {
      try {
        // eslint-disable-next-line no-unused-vars
        const unused = new URL(data);
      } catch (error) {
        this.pbURLValid = false;  
      }
      this.pbURLValid = true;
    },
    validateNewPassword() {
      this.$refs.newPasswordForm.validate();
    }
  }
};
