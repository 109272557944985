export default {
  getPersonalArray (state) {
    return state.personalArray;
  },
  getLoadingState (state) {
    return state.loadingState;
  },
  getPersonalCounter (state) {
    return state.personalCounter;
  }
};