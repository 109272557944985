import {set} from 'vue';

export default {
  setProtocolArray (state, data) {
    state.protocolArray = data;
  },
  updateProtocolArray (state, data) {
    if (data.i === "insert") {
      state.protocolArray.unshift(data.dataToCheck);
      state.protocolArray.pop();
    } else if (data.i === "insert") {
      state.protocolArray.unshift(data.dataToCheck);
    } else {
      set(state.protocolArray, data.i, data.dataToCheck);
    }
  },
  setCounter (state, data) {
    state.protocolCounter = data;
  },
  incCounter (state) {
    state.protocolCounter++;
  },
  setLoadingState (state, bol) {
    state.loadingState = bol;
  }
};