export default {
  getProtocolArray (state) {
    return state.protocolArray;
  },
  getLoadingState (state) {
    return state.loadingState;
  },
  getProtocolCounter (state) {
    return state.protocolCounter;
  }
};