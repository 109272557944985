import {set} from 'vue';

export default {
  setDevicesArray (state, data) {
    state.devicesArray = data;
  },
  updateDevicesArray (state, data) {
    if (data.i === "insert") {
      state.devicesArray.unshift(data.dataToCheck);
      state.devicesArray.pop();
    } else {
      set(state.devicesArray, data.i, data.dataToCheck);
    }
  },
  setCounter (state, data) {
    state.devicesCounter = data;
  },
  incCounter (state) {
    state.devicesCounter++;
  },
  setLoadingState (state, bol) {
    state.loadingState = bol;
  }
};