export default {
  getDeviceData (state) {
    return state.deviceData;
  },
  getUserLocation (state) {
    return state.userLocation;
  },
  getLocationData (state) {
    return state.locationData;
  },
  getLocationText (state) {
    return state.locationText;
  },
  getLocationsWithAll (state) {
    return state.locationsWithAll;
  },
  getFullLocationData (state) {
    return state.fullLocationData;
  },
  getCurrentPage (state) {
    return state.tableInfo.page;
  },
  getTableInfo (state) {
    return state.tableInfo;
  },
  getTableFilter (state) {
    return state.tableFilter;
  },
  getOverlayStatus (state) {
    return state.overlayStatus;
  },
  getLoadingBarStatus (state) {
    return state.loadingBarStatus;
  },
  getReloadStatus (state) {
    return state.reloadStatus;
  },
  getDeliveryArray (state) {
    return state.deliveryArray;
  },
  getSnackbarAlert (state) {
    return state.snackbarAlert;
  }
};