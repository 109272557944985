export default {
  setMaxPage (state, data) {
    state.tableInfo.maxPage = data;
  },
  setModulo (state, data) {
    state.tableInfo.modulo10 = data;
  },
  setCurrentPage (state, data) {
    state.tableInfo.page = data;
  },
  setItemsPerPage (state, data) {
    state.tableInfo.itemsPerPage = data;
  },
  setTableFilterText (state, data) {
    state.tableFilter.filterText = data;
  },
  setTableFilterSettings (state, data) {
    state.tableFilter.filterSettings = data;
  },
  resetTableData (state) {
    state.tableInfo = {maxPage: 1, page: 1, modulo10: false, itemsPerPage: 10};
    state.tableFilter = {filterText: "", filterSettings: {}};
  },
  setDeviceData (state, data) {
    state.deviceData = data;
  },
  setFullLocationData (state, data) {
    state.fullLocationData = data;
  },
  setDataStream (state, data) {
    state.changedDataStream = data;
  },
  setUserLocation (state, data) {
    state.tableFilter.filterSettings.location = data;
    state.userLocation = data;
  },
  setLocationCompany (state, data) {
    const userData = {...this.$auth.user};
    userData.selectedLocation.company = data;
    this.$auth.setUser(userData);
    state.userLocation.company = data;
  },
  setLocationText (state, data) {
    state.locationText = data;
  },
  setLocationsWithAll (state, data) {
    state.locationsWithAll = data;
  },
  setLocationPosition (state, data) {
    const userData = {...this.$auth.user};
    userData.selectedLocation.position = data;
    this.$auth.setUser(userData);
    state.userLocation.position = data;
  },
  setLocationData (state, data) {
    state.locationData = data;
  },
  setOverlayStatus (state, data) {
    state.overlayStatus = data;
  },
  setLoadingBarStatus (state, data) {
    state.loadingBarStatus = data;
  },
  setReloadStatus (state, data) {
    state.reloadStatus = data;
  },
  setDeliveryArray (state, data) {
    state.deliveryArray = data;
  },
  setSnackbarAlert (state, data) {
    data.active = true;
    state.snackbarAlert = data;
  },
  closeSnackbarAlert (state) {
    state.snackbarAlert.active = false;
  }
};