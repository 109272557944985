import {set} from 'vue';

export default {
  setPersonalArray (state, data) {
    state.personalArray = data;
  },
  updatePersonalArray (state, data) {
    if (data.i === "insert") {
      state.personalArray.unshift(data.dataToCheck);
      state.personalArray.pop();
    } else {
      set(state.personalArray, data.i, data.dataToCheck);
    }
  },
  setCounter (state, data) {
    state.personalCounter = data;
  },
  incCounter (state) {
    state.personalCounter++;
  },
  setLoadingState (state, bol) {
    state.loadingState = bol;
  }
};