export default {
  async loadWorkspace (store, inactiveStatus) {
    try {
      const data = await this.$axios.$post('/workspace', inactiveStatus);
      let objKeys = [];
      function sortFunc(a, b) {
        return parseInt(a.k.split(".")[1], 10) - parseInt(b.k.split(".")[1], 10) || parseInt(a.k.split(".")[0].split("-")[1], 10) - parseInt(b.k.split(".")[0].split("-")[1], 10);
      }
      for (let i = 0; i < data.length; i++) {
        for (let u = 0; u < data[i].positions.length; u++) {
          if (data[i].positions[u].departments) {
            objKeys = Object.keys(data[i].positions[u].departments);
            for (let o = 0; o < objKeys.length; o++) {
              data[i].positions[u].departments[objKeys[o]].workspaces.sort(sortFunc);
            }
          }
        }
      }
      store.commit('setWorkspaceArray', data);
    } catch (error) {
      if (error?.response?.status === 401) {
        this.$auth.logout();
        this.$router.push('/login');
      }
      throw (error);
    }
  },
};